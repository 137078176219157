var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.layout.breakPoint && !_vm.layout.collapsed)?_c('span',{staticClass:"backdrop",style:({
      position: 'fixed',
      float: 'left',
      zIndex: 999,
      height: '100vh',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      width: '100vw',
      backgroundColor: 'rgba(0,0,0,0.5)',
      paddingTop: 0,
      transition: 'all 0.3s ease-in-out',
    }),on:{"click":() => _vm.layout.breakPoint && _vm.onCollapse(true)}}):_vm._e(),_c('a-layout-sider',{staticClass:"sidebar-container",style:({
      position: 'fixed',
      zIndex: 999,
      height: '100vh',
      left: 0,
      paddingTop: 0,
    }),attrs:{"breakpoint":"lg","width":256,"collapsed":_vm.layout.collapsed,"trigger":null,"collapsible":true,"collapsed-width":_vm.layout.breakPoint ? '0px' : '88px'},on:{"breakpoint":_vm.onBreakPoint}},[_c('div',{staticClass:"sidebar-container-logo"},[(_vm.layout.breakPoint)?_c('a-button',{staticClass:"btn-close",attrs:{"shape":"circle","icon":"close"},on:{"click":() => _vm.onCollapse(true)}}):_vm._e()],1),_c('perfect-scrollbar',[_c('div',{staticClass:"scroll-bar",style:({
          maxHeight: 'CALC(100vh - 88.94px)',
          paddingBottom: 25,
        })},[(_vm.authenticated)?_c('a-menu',{style:({
            borderRight: 0,
            height: 'calc(100% - 16px)',
            background: 'transparent',
          }),attrs:{"theme":"dark","mode":"inline","openKeys":_vm.layout.openKeys.filter((key) => !!key) || [],"selectedKeys":_vm.layout.selectedKeys || [],"subMenuCloseDelay":0.1,"subMenuOpenDelay":0.1},on:{"openChange":_vm.onOpenChange,"select":_vm.onSelectMenuChange}},[_vm._l((_vm.menus),function(menu){return [(menu.children && menu.children.length)?_c('a-sub-menu',{key:menu.key},[_c('span',{attrs:{"slot":"title"},slot:"title"},[(menu.icon)?_c(menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon',{tag:"component",class:menu.isFontAwesome && 'anticon',attrs:{"icon":menu.icon,"type":menu.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(menu.title))])],1),_vm._l((menu.children),function(submenu){return _c('a-menu-item',{key:submenu.key,attrs:{"to":{ name: submenu.key }},on:{"click":() => _vm.layout.breakPoint && _vm.onCollapse(true)}},[(submenu.icon)?_c(submenu.isFontAwesome ? 'font-awesome-icon' : 'a-icon',{tag:"component",class:submenu.isFontAwesome && 'anticon',attrs:{"icon":submenu.icon,"type":submenu.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(submenu.title))]),(submenu.key)?_c('router-link',{attrs:{"to":{ name: submenu.key }}}):_vm._e()],1)})],2):_c('a-menu-item',{key:menu.key,attrs:{"to":{ name: menu.key }},on:{"click":() => _vm.layout.breakPoint && _vm.onCollapse(true)}},[(menu.icon)?_c(menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon',{tag:"component",class:menu.isFontAwesome && 'anticon',attrs:{"icon":menu.icon,"type":menu.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(menu.title))]),(menu.key)?_c('router-link',{attrs:{"to":{ name: menu.key }}}):_vm._e()],1)]})],2):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }