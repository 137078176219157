import _ from "lodash";
import ls from "./localStorage";
import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

const USR_MENU_VAR = `lottery_menu_config`.toUpperCase(),
  PAGE_TITLE_VAR = `Banloto`,
  ADMIN_PAGE_TITLE_VAR = `${PAGE_TITLE_VAR} (Admin)`,
  apiLsVariable = `lottery-rd-session`.toUpperCase(),
  apiLsClientVariable = `lottery-rd-client-session`.toUpperCase(),
  serverDateFormat = "YYYY-MM-DD H:s:i";
const stringInitials = ($string) => {
  let initials = $string.match(/\b\w/g) || [];
  initials = initials.join("").toUpperCase();
  return initials;
};

const getUserData = (token = "") => {
  if (token && token !== "") {
    const decodedToken = jwtDecode(token);
    const userData = decodedToken.user;
    if (typeof userData !== "undefined") {
      return userData;
    }
  }
  return null;
};

const isValidEmail = (email) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

const strongPassword = (password = "") => {
  if (!password) return -1;
  /*eslint no-useless-escape: "error"*/
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})"
  );
  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );
  if (strongRegex.test(password)) {
    return 1;
  } else if (mediumRegex.test(password)) {
    return 0;
  } else {
    return -1;
  }
};

const storeMenus = (menu) => {
  try {
    ls.set(USR_MENU_VAR, window.btoa(JSON.stringify(menu)));
    return true;
  } catch (error) {
    console.error("ERROR: ", error);
  }
  return false;
};

const getStoredMenus = () => {
  try {
    let data = ls.get(USR_MENU_VAR) || null;
    if (data) data = JSON.parse(window.atob(data));
    return data;
  } catch (error) {
    console.error("ERROR: ", error);
  }
  return null;
};

const roles = {
  admin: {
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_USER: "ROLE_USER",
    ROLE_USERS: "ROLE_USERS",
    ROLE_ADMINS: "ROLE_ADMINS",
    ROLE_ADMINS_CREATE: "ROLE_ADMINS_CREATE",
    ROLE_ADMINS_UPDATE: "ROLE_ADMINS_UPDATE",
    ROLE_ADMINS_DELETE: "ROLE_ADMINS_DELETE",
    ROLE_USERS_UPDATE: "ROLE_USERS_UPDATE",
    ROLE_USERS_ACTIVATE: "ROLE_USERS_ACTIVATE",
    ROLE_USERS_DESACTIVATE: "ROLE_USERS_DESACTIVATE",
    ROLE_USERS_DELETE: "ROLE_USERS_DELETE",
    ROLE_USERS_COMMISSIONS_PERCENTAGE_ASSIGN:
      "ROLE_USERS_COMMISSIONS_PERCENTAGE_ASSIGN",
    ROLE_LOTTERIES: "ROLE_LOTTERIES",
    ROLE_LOTTERIES_CREATE: "ROLE_LOTTERIES_CREATE",
    ROLE_LOTTERIES_UPDATE: "ROLE_LOTTERIES_UPDATE",
    ROLE_LOTTERIES_DELETE: "ROLE_LOTTERIES_DELETE",
    ROLE_RAFFLES: "ROLE_RAFFLES",
    ROLE_RAFFLES_SAVE: "ROLE_RAFFLES_SAVE",
    ROLE_RAFFLES_DELETE: "ROLE_RAFFLES_DELETE",
    ROLE_BLOCKS: "ROLE_BLOCKS",
    ROLE_BLOCKS_CREATE: "ROLE_BLOCKS_CREATE",
    ROLE_BLOCKS_UPDATE: "ROLE_BLOCKS_UPDATE",
    ROLE_BLOCKS_DELETE: "ROLE_BLOCKS_DELETE",
    ROLE_TRANSACTIONS: "ROLE_TRANSACTIONS",
    ROLE_TRANSACTIONS_PROCESS: "ROLE_TRANSACTIONS_PROCESS",
    ROLE_TRANSACTIONS_CANCEL: "ROLE_TRANSACTIONS_CANCEL",
    ROLE_TRANSACTIONS_APPROVE: "ROLE_TRANSACTIONS_APPROVE",
    ROLE_TRANSACTIONS_REJECT: "ROLE_TRANSACTIONS_REJECT",
    ROLE_TRANSACTIONS_CONFIRM: "ROLE_TRANSACTIONS_CONFIRM",
    ROLE_BALANCE_MOVEMENTS: "ROLE_BALANCE_MOVEMENTS",
    ROLE_COMMISSIONS_GENERATE: "ROLE_COMMISSIONS_GENERATE",
    ROLE_COMMISSIONS_PROCESS: "ROLE_COMMISSIONS_PROCESS",
    ROLE_ORDERS: "ROLE_ORDERS",
    ROLE_ORDERS_CANCEL: "ROLE_ORDERS_CANCEL",
    ROLE_PROCESS_WINNING: "ROLE_PROCESS_WINNING",
    ROLE_USER_COMMISSION_PROFILES: "ROLE_USER_COMMISSION_PROFILES",
    ROLE_USER_COMMISSION_PROFILES_CREATE:
      "ROLE_USER_COMMISSION_PROFILES_CREATE",
    ROLE_USER_COMMISSION_PROFILES_UPDATE:
      "ROLE_USER_COMMISSION_PROFILES_UPDATE",
    ROLE_USER_COMMISSION_PROFILES_DELETE:
      "ROLE_USER_COMMISSION_PROFILES_DELETE",
    ROLE_MONITORING_REPORT: "ROLE_MONITORING_REPORT",
    ROLE_LOTTERIES_CLOSING_SCHEDULE: "ROLE_LOTTERIES_CLOSING_SCHEDULE",
    ROLE_USER_TRANSACTION_PROFILES: "ROLE_USER_TRANSACTION_PROFILES",
    ROLE_USER_TRANSACTION_PROFILES_CREATE:
      "ROLE_USER_TRANSACTION_PROFILES_CREATE",
    ROLE_USER_TRANSACTION_PROFILES_UPDATE:
      "ROLE_USER_TRANSACTION_PROFILES_UPDATE",
    ROLE_USER_TRANSACTION_PROFILES_DELETE:
      "ROLE_USER_TRANSACTION_PROFILES_DELETE",
    ROLE_RAFFLES_CONFIRM: "ROLE_RAFFLES_CONFIRM",
    ROLE_USER_CREDIT_CARD_MODIFY: "ROLE_USER_CREDIT_CARD_MODIFY",
  },
  client: {
    ROLE_SELLER: "ROLE_SELLER",
    ROLE_BUYER: "ROLE_BUYER",
  },
};

const hasRoles = (userRoles = [], assignedRoles = []) => {
  assignedRoles = [...assignedRoles, ...[roles.admin.ROLE_ADMIN]];
  if (!assignedRoles || !_.isArray(assignedRoles)) return false;
  if (!userRoles || !_.isArray(userRoles)) return false;
  return _.intersection(userRoles, assignedRoles).length > 0;
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const maskDni = (dni) => {
  if (!dni) return "";
  return dni.toString().replace(/^(\d{3})(\d{7})(\d{1})$/, "$1-$2-$3");
};

const pageTitle = (title) => {
  if (!title) return PAGE_TITLE_VAR;
  return `${PAGE_TITLE_VAR} - ${title}`;
};

const adminPageTitle = (title) => {
  if (!title) return ADMIN_PAGE_TITLE_VAR;
  return `${ADMIN_PAGE_TITLE_VAR} - ${title}`;
};

const getYear = () => {
  const date = new Date();
  const year = date.getFullYear();
  return year === 2022 ? 2022 : `2022-${year}`;
};

const generateUniqueID = () => {
  return uuidv4();
};

const CRAUE_CONFIG = {
  VAR_REFERRAL_COMMISSION_APPLY_AFTER_SPEND_AMOUNT:
    "referral_commission_apply_after_spend_amount",
  VAR_USER_COMMISSIONS_OPTIONS: "user_commissions_options",
  VAR_USER_REFERRAL_COMMISSION_AMOUNT: "user_referral_commission_amount",
  VAR_USER_REFERRAL_RULES: "user_referral_rules",
};

const DATE_FORMAT = {
  MOMENT_DATE_TIME: "DD/MM/YYYY hh:mm A",
  MOMENT_DATE_TIME_LONG: "DD/MM/YYYY hh:mm:ss A",
  MOMENT_DAY_MONTH: "DD MMM",
  MOMENT_DAY_MONTH_YEAR: "DD MMM YYYY",
  MOMENT_DATE: "DD/MM/YYYY",
  MOMENT_TIME: "hh:mm A",
  SERVER_DATE_TIME: "YYYY-MM-DD hh:mm:ss",
  SERVER_DATE: "YYYY-MM-DD",
};

const PLAY_TYPES = {
  QUINIELA: "quiniela",
  PALE: "pale",
  TRIPLET: "tripleta",
  SUPERPALE: "superpale",
};

const numbersCombinations = (play) => {
  const numbers = play.split("-");
  const combinations = [];
  switch (numbers.length) {
    case 2:
      combinations.push(`${numbers[0]}-${numbers[1]}`);
      combinations.push(`${numbers[1]}-${numbers[0]}`);
      break;
    case 3:
      combinations.push(`${numbers[0]}-${numbers[1]}-${numbers[2]}`);
      combinations.push(`${numbers[0]}-${numbers[2]}-${numbers[1]}`);
      combinations.push(`${numbers[1]}-${numbers[0]}-${numbers[2]}`);
      combinations.push(`${numbers[1]}-${numbers[2]}-${numbers[0]}`);
      combinations.push(`${numbers[2]}-${numbers[0]}-${numbers[1]}`);
      combinations.push(`${numbers[2]}-${numbers[1]}-${numbers[0]}`);
      break;
    default:
      combinations.push(play);
  }
  return combinations;
};

const validateEmail = (value = []) => {
  return value.filter((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  });
};

const toBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

export {
  USR_MENU_VAR,
  PAGE_TITLE_VAR,
  apiLsVariable,
  apiLsClientVariable,
  jwtDecode,
  stringInitials,
  getUserData,
  isValidEmail,
  strongPassword,
  storeMenus,
  getStoredMenus,
  hasRoles,
  roles,
  getBase64,
  maskDni,
  serverDateFormat,
  pageTitle,
  adminPageTitle,
  getYear,
  CRAUE_CONFIG,
  DATE_FORMAT,
  generateUniqueID,
  PLAY_TYPES,
  numbersCombinations,
  validateEmail,
  toBase64,
};
