import axios from "axios";
import Qs from "qs";
import { apiLsVariable } from "./utils";
import store from "../store";
import localStorageService from "./localStorage";
import router from "../router";

const myToken = () => {
  let session = localStorageService.get(apiLsVariable) ?? null;
  if (session) {
    let { token = null } = JSON.parse(window.atob(session));
    return token;
  }
  return null;
};

export const restClient = (
  route,
  method,
  data = null,
  params = {},
  extraHeaders = {}
) => {
  const token = myToken();
  if (!token)
    Promise.reject({ has_error: true, code: 401, message: "Accesso Denegado" });
  return axios({
    url: `${route}`,
    method: method,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      ...extraHeaders,
    },
    json: true,
    params: params,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: "brackets" });
    },
  })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(async (error) => {
      if (error.response) {
        if ([401, 403].includes(error.response.status)) {
          if (
            token &&
            !route?.includes("logout") &&
            !window.location.pathname?.includes("login")
          ) {
            await store.dispatch("adminAuth/handleLogout");
          }
          if (router.currentRoute.name !== "admin")
            router.push({ name: "admin" });
        }
        return Promise.reject(error);
      } else if (error.request) {
        return Promise.reject(getUnknownError());
      } else {
        return Promise.reject(getUnknownError());
      }
    });
};

export const downloadFile = (route, fileName) => {
  const token = myToken();
  return axios({
    url: `${route}`,
    method: "GET",
    responseType: "blob", // important,
    headers: {
      Authorization: `Bearer ${token}`,
      "Cache-Control": "no-cache",
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`); //or any other extension
      link.setAttribute("target", "_blank"); //or any other extension
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const previewFile = (route) => {
  const token = myToken();
  if (!token) throw { has_error: true, code: 401, message: "Accesso Denegado" };
  return axios({
    url: `${route}`,
    method: "GET",
    responseType: "blob", // important,
    headers: {
      Authorization: `Bearer ${token}`,
      "Cache-Control": "no-cache",
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank"); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const restClientWithOutAuthentication = (
  route,
  method,
  data = null,
  params = {},
  extraHeaders = {}
) => {
  return axios({
    url: `${route}`,
    method: method,
    headers: {
      ...extraHeaders,
    },
    data: data,
    json: true,
    params: params,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: "brackets" });
    },
  })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error);
      } else if (error.request) {
        return Promise.reject(getUnknownError());
      } else {
        return Promise.reject(getUnknownError());
      }
    });
};

export const restClientAll = (
  route,
  method,
  data = null,
  params = {},
  extraHeaders = {}
) => {
  const token = myToken();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  return axios({
    url: `${route}`,
    method: method,
    headers: {
      ...headers,
      ...extraHeaders,
    },
    data: data,
    json: true,
    params: params,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: "brackets" });
    },
  })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(async (error) => {
      if (error.response) {
        if ([401, 403].includes(error.response.status)) {
          if (
            token &&
            !route?.includes("logout") &&
            !window.location.pathname?.includes("login")
          ) {
            await store.dispatch(`adminAuth/handleLogout`);
          }
          if (router.currentRoute.name !== "admin")
            router.push({ name: "admin" });
        }
        return Promise.reject(error);
      } else if (error.request) {
        return Promise.reject(getUnknownError());
      } else {
        return Promise.reject(getUnknownError());
      }
    });
};

/* Unknow error */
const getUnknownError = () => ({
  has_error: true,
  additional_errors: null,
  data: null,
  code: 500,
  message:
    "El servidor no puede procesar la solicitud por un motivo desconocido.",
  redirect: null,
});
