import _ from "lodash";
import { path } from "../../api";
import { restClient } from "../../utils/restClient";

const initialState = {};

export default {
  namespaced: true,
  name: "shortUrl",
  state: _.cloneDeep(initialState),
  actions: {
    async generateShortUrl(_, payload = null) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.paywallLink,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
