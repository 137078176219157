import Vue from "vue";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import { getVeeValidateInstance } from "./components/core/VeeValidateForm";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./utils/imports";
import "./filters";
import { apiLsVariable, getUserData } from "./utils/utils";
import * as actions from "./utils/actions";
import localStorageService from "./utils/localStorage";
import { version } from "../package.json";

Vue.config.productionTip = false;

// Implements alternative form-inputs vee-validator
getVeeValidateInstance(Vue);

// Sentry
if (process.env?.VUE_APP_SENTRY_DSN?.length > 1) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: version,
    sendDefaultPii: true,
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", process.env.VUE_APP_DOMAIN],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  async beforeCreate() {
    let session = localStorageService.get(apiLsVariable) ?? null;
    session = JSON.parse(session ? window.atob(session) : null);
    if (session) {
      const userData = await getUserData(session.token);
      if (userData.module === "admin") {
        await store.dispatch("adminAuth/checkSession");
      } else {
        localStorageService.remove(apiLsVariable);
        await store.commit(`adminAuth/${actions.ADMIN_LOGOUT}`, null);
      }
    }
  },
}).$mount("#app");
