// Tools
import Vue from "vue";
import VueRouter from "vue-router";

// Role List
import { apiLsVariable, getUserData, hasRoles, roles } from "@/utils/utils";

// Layouts
import AdminLayout from "@/layouts/AdminLayout.vue";
import store from "@/store";
import * as actions from "@/utils/actions";
import localStorageService from "@/utils/localStorage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "admin",
    exact: true,
    component: () =>
      import(/* webpackChunkName: "admin-page" */ "@/views/admin/Login.vue"),
    meta: {
      requiresAuth: false,
      module: "admin",
    },
    beforeEnter(to, from, next) {
      if (localStorageService.get(apiLsVariable)) {
        next({ name: "admin.dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/password-reset",
    name: "admin.password.reset",
    exact: true,
    component: () =>
      import(
        /* webpackChunkName: "admin-password-reset-page" */ "@/views/admin/PasswordReset.vue"
      ),
    meta: {
      requiresAuth: false,
      module: "admin",
    },
    beforeEnter(to, from, next) {
      if (store.state.adminAuth.authenticated) {
        next({ name: "admin.dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/",
    component: AdminLayout,
    children: [
      {
        path: "/dashboard",
        name: "admin.dashboard",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-dashboard-page" */ "@/views/admin/Dashboard.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_USER],
        },
      },
      {
        path: "/reports/monitoring",
        name: "admin.reports.monitoring",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-reports-monitoring-page" */ "@/views/admin/reports/MonitoringReport.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_MONITORING_REPORT,
          ],
        },
      },
      {
        path: "/admins",
        name: "admin.admins",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-admins-page" */ "@/views/admin/admins/AdminList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_ADMINS],
        },
      },
      {
        path: "/admins/create",
        name: "admin.admins.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-admins-form-page" */ "@/views/admin/admins/AdminForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_ADMINS_CREATE,
          ],
        },
      },
      {
        path: "/admins/:id",
        name: "admin.admins.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-admins-form-page" */ "@/views/admin/admins/AdminForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_ADMINS,
            roles.admin.ROLE_ADMINS_UPDATE,
          ],
        },
      },
      {
        path: "/users",
        name: "admin.users",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-users-page" */ "@/views/admin/users/UserList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_USERS],
        },
      },
      {
        path: "/users/:id",
        name: "admin.users.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-users-form-page" */ "@/views/admin/users/UserForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USERS,
            roles.admin.ROLE_USERS_UPDATE,
          ],
        },
      },
      {
        path: "/lotteries",
        name: "admin.lotteries",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-page" */ "@/views/admin/lotteries/LotteryList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_LOTTERIES],
        },
      },
      {
        path: "/lotteries/create",
        name: "admin.lotteries.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-form-page" */ "@/views/admin/lotteries/LotteryForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES_CREATE,
          ],
        },
      },
      {
        path: "/lotteries/:id",
        name: "admin.lotteries.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-form-page" */ "@/views/admin/lotteries/LotteryForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES,
            roles.admin.ROLE_LOTTERIES_UPDATE,
          ],
        },
      },
      {
        path: "/lotteries-closing-schedules",
        name: "admin.lotteries-closing-schedules",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-closing-schedules-page" */ "@/views/admin/lotteries-schedules/ScheduleList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE,
          ],
        },
      },
      {
        path: "/lotteries-closing-schedules/create",
        name: "admin.lotteries-closing-schedules.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-closing-schedules-create-page" */ "@/views/admin/lotteries-schedules/ScheduleForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE,
          ],
        },
      },
      {
        path: "/lotteries-closing-schedules/:id",
        name: "admin.lotteries-closing-schedules.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-closing-schedules-edit-page" */ "@/views/admin/lotteries-schedules/ScheduleForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE,
          ],
        },
      },
      {
        path: "/raffles",
        name: "admin.raffles",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-raffles-page" */ "@/views/admin/raffles/RaffleList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_RAFFLES],
        },
      },
      {
        path: "/raffles/create",
        name: "admin.raffles.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-raffles-form-page" */ "@/views/admin/raffles/RaffleForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_RAFFLES_SAVE],
        },
      },
      {
        path: "/raffles/:date",
        name: "admin.raffles.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-raffles-form-page" */ "@/views/admin/raffles/RaffleForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_RAFFLES,
            roles.admin.ROLE_RAFFLES_SAVE,
          ],
        },
      },
      {
        path: "/blocks",
        name: "admin.blocks",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-blocks-page" */ "@/views/admin/blocks/BlockList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_BLOCKS],
        },
      },
      {
        path: "/blocks/create",
        name: "admin.blocks.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-blocks-form-page" */ "@/views/admin/blocks/BlockForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_BLOCKS_CREATE,
          ],
        },
      },
      {
        path: "/blocks/:id",
        name: "admin.blocks.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-blocks-form-page" */ "@/views/admin/blocks/BlockForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_BLOCKS,
            roles.admin.ROLE_BLOCKS_UPDATE,
          ],
        },
      },
      {
        path: "/transactions",
        name: "admin.transactions",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transactions-page" */ "@/views/admin/transactions/TransactionList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_TRANSACTIONS],
        },
      },
      {
        path: "/transactions/process",
        name: "admin.transactions.process",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-process-page" */ "@/views/admin/transactions/TransactionForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_TRANSACTIONS_PROCESS,
          ],
        },
      },
      {
        path: "/transactions/:id/view",
        name: "admin.transactions.view",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-view-page" */ "@/views/admin/transactions/TransactionForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_TRANSACTIONS],
        },
      },
      {
        path: "/balance-movements",
        name: "admin.balance-movements",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-balance-movements-page" */ "@/views/admin/balance-movements/UserBalanceMovementsList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_BALANCE_MOVEMENTS,
          ],
        },
      },
      {
        path: "/commissions",
        name: "admin.commissions",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-commissions-page" */ "@/views/admin/commissions/Report.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_COMMISSIONS_GENERATE,
          ],
        },
      },
      {
        path: "/orders",
        name: "admin.orders",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-orders-page" */ "@/views/admin/orders/OrderList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_ORDERS],
        },
      },
      {
        path: "/orders/:id",
        name: "admin.orders.view",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-orders-view-page" */ "@/views/admin/orders/OrderView.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_ORDERS],
        },
      },
      {
        path: "/system-config",
        name: "admin.system-config",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-system-config-page" */ "@/views/admin/config/SystemConfig.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN],
        },
      },
      {
        path: "/commission-profiles",
        name: "admin.commission-profiles",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-commission-profiles-page" */ "@/views/admin/commission-profiles/CommissionProfileList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_COMMISSION_PROFILES,
          ],
        },
      },
      {
        path: "/commission-profiles/create",
        name: "admin.commission-profiles.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-commission-profiles-form-page" */ "@/views/admin/commission-profiles/CommissionProfileForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_COMMISSION_PROFILES_CREATE,
          ],
        },
      },
      {
        path: "/commission-profiles/:id",
        name: "admin.commission-profiles.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-commission-profiles-form-page" */ "@/views/admin/commission-profiles/CommissionProfileForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_COMMISSION_PROFILES,
            roles.admin.ROLE_USER_COMMISSION_PROFILES_UPDATE,
          ],
        },
      },
      {
        path: "/transaction-profiles",
        name: "admin.transaction-profiles",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-profiles-page" */ "@/views/admin/transaction-profiles/TransactionProfileList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_TRANSACTION_PROFILES,
          ],
        },
      },
      {
        path: "/transaction-profiles/create",
        name: "admin.transaction-profiles.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-profiles-form-page" */ "@/views/admin/transaction-profiles/TransactionProfileForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_TRANSACTION_PROFILES_CREATE,
          ],
        },
      },
      {
        path: "/transaction-profiles/:id",
        name: "admin.transaction-profiles.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-profiles-form-page" */ "@/views/admin/transaction-profiles/TransactionProfileForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_TRANSACTION_PROFILES,
            roles.admin.ROLE_USER_TRANSACTION_PROFILES_UPDATE,
          ],
        },
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // Function to scroll to top on change page
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    let session = localStorageService.get(apiLsVariable);
    if (!session) {
      store.dispatch(`adminAuth/handleLogout`);
      store.state.redirectRoute = to.name;
      next({
        name: "admin",
      });
    } else {
      session = session ? JSON.parse(window.atob(session)) : null;
      const userData = store.state.adminAuth.user || getUserData(session.token);
      if (to.meta.allowedRoles && to.meta.allowedRoles.length > 0) {
        const userRoles = userData?.accessRoles || [];
        const allowedRoles = to.meta.allowedRoles;
        if (!hasRoles(userRoles, allowedRoles)) {
          store.state.redirectRoute = null;
          next({
            name: "admin",
          });
        } else if (store?.state?.redirectRoute) {
          const redirectRoute = store.state.redirectRoute;
          store.state.redirectRoute = null;
          next({ name: redirectRoute });
        }
      }

      store.commit(`adminAuth/${actions.ADMIN_LOGIN}`, {
        user: userData,
        token: session.token,
        expires_in: session.expires_in,
        authenticated: !!userData,
      });
    }
  }
  next();
});

export default router;
